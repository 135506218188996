<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <v-spacer></v-spacer>
          <v-btn
            v-if="showCreateButton"
            style="float: right"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm ms-3"
            color="#5e72e4"
            @click="createNewItem"
            ><v-icon class="me-3">ni-fat-add</v-icon>
            Create New Order
          </v-btn>
        </v-col>
        <v-col cols="12" class="mt-n3">
          <v-card>
            <v-tabs v-model="tab" icons-and-text fixed-tabs>
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="(tab, index) in tabHeadings"
                :key="'orders-tabs-' + index"
                :href="`#tab_${tab.anchor}`"
              >
                <v-badge
                  v-if="tabItems[tab.id].length > 0"
                  color="red"
                  :content="tabItems[tab.id].length"
                >
                  {{ tab.title }}
                </v-badge>
                <span v-else>
                  {{ tab.title }}
                </span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(tab, index) in tabHeadings"
                :key="'orders-tab-items-' + index"
                :value="`tab_${tab.anchor}`"
              >
                <simple-table
                  :show-create-new-item-button="false"
                  :enabled-action-edit="tab.enableActionEdit"
                  :enabled-action-deactivate="tab.enableActionDeactivate"
                  :enabled-action-delete="tab.enableActionDelete"
                  :enable-action-go-to-item-page="tab.enableActionGoToItemPage"
                  :enabled-action-approve="tab.enableActionApprove"
                  :enabled-action-assign-packer="tab.enableActionAssignPacker"
                  :delete-tooltip="tab.deleteTooltip"
                  :approve-tooltip="tab.approveTooltip"
                  :headers="tab.headers"
                  :items="tabItems[tab.id]"
                  :item-key="'orders-table-' + tab.id + '-' + index"
                  :pagination-details="paginationDetails"
                  no-data-text="No data"
                  :showSearchBox="true"
                  :searchPlaceholder="'Search order number or shop name...'"
                  :showFilterBy="tab.showFilterBy && showAdvancedFilters"
                  :filterByIdentifier="'driver'"
                  :filterByPlaceholder="filterByPlaceholder"
                  :filterbyItems="driverUserList"
                  :filterbyItemText="'friendlyName'"
                  :filterbyItemValue="'driverId'"
                  @updateFilterBy="updateSearchParameters"
                  :showFilterByDate="showAdvancedFilters"
                  :filterByDatePlaceholder="filterByDatePlaceholder"
                  :show-total-value="tab.showTotalCodValue"
                  :total-value-text="`Total Expected CoD Value`"
                  :total-value="tabItemsTotals[tab.id]"
                  :sub-total-value-text="`Total Collected CoD Value`"
                  :sub-total-value="tabItemsTotalsCollected[tab.id]"
                  @clearFilters="clearFilters"
                  @updateSearchValue="updateShopSearchParam"
                  @approveItem="approveItem"
                  @assignPacker="showDialogAssignPacker"
                  @createNewItem="createNewItem"
                  @deleteItem="deleteItem"
                  @editItem="editItem"
                  @fetchNextPage="fetchDataByUrl(paginationDetails.nextPageUrl)"
                  @fetchPreviousPage="
                    fetchDataByUrl(this.paginationDetails.previousPageUrl)
                  "
                  @updateItemsPerPage="updateItemsPerPage"
                  @updateSelectedPage="updateSelectedPage"
                ></simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialogAssignPacker" max-width="500px">
        <v-card class="card-shadow card-padding">
          <v-card-title class="pt-0 text-h4 text-typo justify-center"
            >Assign Packer</v-card-title
          >
          <v-card-text>
            <v-select
              outlined
              class="input-style font-size-input text-light-input placeholder-light"
              placeholder="Choose packer"
              :items="packers"
              item-text="name"
              item-value="id"
              v-model="selectedOrderPacker"
              required
            >
            </v-select>
          </v-card-text>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDialogAssignPacker"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-danger py-3 px-6 rounded-sm"
              color="#f5365c"
              >Cancel</v-btn
            >

            <v-btn
              @click="assignPackerToOrder"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-primary py-3 px-6 rounded-sm"
              color="#5e72e4"
              >Assign</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import SimpleTable from "@/components/Tables/SimpleTable.vue";

const standardTableHeaders = [
  {
    text: "Order #",
    align: "start",
    sortable: false,
    value: "orderReference",
  },
  { text: "Delivery Date", value: "deliveryDate" },
  { text: "Shop", value: "shop" },
  { text: "No Products", value: "productsCount" },
  { text: "Value", value: "value" },
  { text: "Status", value: "status" },
  { text: "Actions", value: "actions", sortable: false },
];

export default {
  name: "orders",
  components: {
    HeaderTopDashboard,
    SimpleTable,
  },
  data() {
    return {
      assignPackerShortcutOrder: null,
      dialogAssignPacker: false,
      baseUrl: "/orders?size=200",
      dataLoadInterval: null,
      headers: [
        {
          text: "Order #",
          align: "start",
          sortable: false,
          value: "orderReference",
        },
        { text: "Delivery Date", value: "deliveryDate" },
        { text: "Shop", value: "shop" },
        { text: "No Products", value: "productsCount" },
        { text: "Value", value: "value" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      fullItems: [],
      packers: [],
      paginationDetails: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 40,
        pageStart: 0,
        pageStop: 0,
        itemsLength: 0,
        firstPageUrl: "",
        lastPageUrl: "",
        nextPageUrl: "",
        previousPageUrl: "",
        links: [],
        path: "",
      },
      searchValue: "",
      selectedOrderPacker: null,
      // tab: null,
      tabHeadings: [],
      tabItems: {
        newOrders: [],
        packing: [],
        alternativesAndReplacements: [],
        awaitingReplacementConfirmation: [],
        awaitingPaymentCod: [],
        awaitingPaymentElectronic: [],
        fulfillment: [],
        cartOrders: [],
        cancelledOrders: [],
        restoreStock: [],
      },
      tabItemsTotals: {},
      tabItemsTotalsCollected: {},
      driverList: [],
      driverUserList: [],
      searchFilterParameters: [],
      filterByShopParams: {
        field: "shop",
        operator: "",
        value: null,
      },
      filterByDriverParams: {
        field: "driver",
        operator: "",
        value: null,
      },
      filterByDateParams: {
        field: "delivery_date",
        operator: "",
        value: null,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },

    getSearchFilterParams() {
      const result = [];

      if (this.filterByShopParams.value) {
        result.push(this.filterByShopParams);
      }

      if (this.filterByDriverParams.value) {
        result.push(this.filterByDriverParams);
      }

      if (this.filterByDateParams.value) {
        result.push(this.filterByDateParams);
      }

      return result;
    },

    searchPlaceholder() {
      if (this.filterByShopParams.value) {
        return this.filterByShopParams.value;
      }

      return "Search shop name...";
    },

    filterByPlaceholder() {
      if (this.filterByDriverParams.value) {
        const driverObj = this.driverUserList.find(
          (d) => d.driverId === this.filterByDriverParams.value
        );
        return driverObj.friendlyName;
      }

      return "Filter by driver...";
    },

    filterByDatePlaceholder() {
      if (this.filterByDateParams.value) {
        return this.filterByDateParams.value;
      }

      return "Filter by delivery date...";
    },

    showAdvancedFilters() {
      if (
        this.sessionStore.user.roles &&
        (this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin") ||
          this.sessionStore.user.roles.includes("agent") ||
          this.sessionStore.user.roles.includes("warehouse_manager"))
      ) {
        return true;
      }

      return false;
    },

    showCreateButton() {
      if (
        this.sessionStore.user.roles &&
        (this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin") ||
          this.sessionStore.user.roles.includes("agent") ||
          this.sessionStore.user.roles.includes("warehouse_manager"))
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    approveItem(itemId) {
      const item = this.fullItems.find((obj) => obj.id === itemId);

      let newStatus = "";

      // if (item.status === "paid" || item.status === "awaiting_payment") {
      //   newStatus = "packaging";
      // } else
      if (item.status === "packaging") {
        if (item.payment_type === "cash_on_delivery") {
          newStatus = "review_and_assign_driver";
        } else {
          newStatus = "awaiting_payment";
        }
      } else if (
        item.status === "awaiting_payment" &&
        item.payment_type === "cash_on_delivery"
      ) {
        newStatus = "delivered";
      } else if (item.status === "cancelled_restore_stock") {
        newStatus = "cancelled";
      }

      if (newStatus !== "") {
        const body = {
          status: newStatus,
        };

        this.$http
          .patch(`/orders/${item.id}`, body, this.sessionStore.getHttpConfig)
          .then(() => {
            this.fetchDataByUrl(this.baseUrl);
            this.$notify.success(
              `Order successfully moved to "${newStatus}" status.`
            );
          })
          .catch((error) => {
            this.handleHttpError(
              error,
              "An error occurred while approving the order."
            );
          });
      }
    },

    assignPackerToOrder() {
      if (this.selectedOrderPacker === null) {
        this.$notify.error("Please select a packer first.");
        return;
      }

      const body = {
        status: "packaging",
        packer: this.selectedOrderPacker,
      };

      this.$http
        .patch(
          `/orders/${this.assignPackerShortcutOrder.id}`,
          body,
          this.sessionStore.getHttpConfig
        )
        .then(() => {
          this.fetchDataByUrl(this.baseUrl);
          this.$notify.success("Packer assigned successfully.");
          this.closeDialogAssignPacker();
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while assigning the packer. Please try again or contact support."
          );
        });
    },

    closeDialogAssignPacker() {
      this.dialogAssignPacker = false;
    },

    createNewItem() {
      this.$router.push("/orders/create");
    },

    deleteItem(itemId) {
      const item = this.fullItems.find((obj) => obj.id === itemId);
      if (
        item.status === "awaiting_payment" &&
        item.payment_type === "cash_on_delivery"
      ) {
        const body = {
          status: "delivered_unpaid",
        };

        this.$http
          .patch(`/orders/${itemId}`, body, this.sessionStore.getHttpConfig)
          .then(() => {
            this.fetchDataByUrl(this.baseUrl);
            this.$notify.success("Order successfully marked as unpaid.");
          })
          .catch((error) => {
            this.handleHttpError(
              error,
              "An error occurred while updating the order."
            );
          });
      } else {
        this.$http
          // .delete(`/orders/${item.id}`, this.sessionStore.getHttpConfig)
          .delete(`/orders/${itemId}`, this.sessionStore.getHttpConfig)
          .then(() => {
            this.$notify.success("Order successfully deleted.");
            this.fetchDataByUrl("/orders?size=30");
          })
          .catch((error) => {
            this.handleHttpError(
              error,
              "An error occurred while deleting the order."
            );
          });
      }
    },

    editItem(item) {
      const originalItem = this.fullItems.find(
        (element) => (element.id = item.id)
      );

      if (!originalItem) {
        this.$notify.error(
          "An error occurred while preparing to modify this order. Please try again later or contact support."
        );
        return;
      }

      this.$router.push({
        name: "Edit Order",
        params: {
          id: `${item.id}`,
        },
      });
    },

    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      if (!url.includes("search?")) {
        this.baseUrl = "/orders?size=200";
        this.paginationDetails.loading = true;
        this.$http
          .get(this.baseUrl, this.sessionStore.getHttpConfig)
          .then((result) => {
            this.items.splice(0);
            this.fullItems.splice(0);
            this.tabItems.newOrders.splice(0);
            this.tabItems.packing.splice(0);
            this.tabItems.alternativesAndReplacements.splice(0);
            this.tabItems.awaitingReplacementConfirmation.splice(0);
            this.tabItems.awaitingPaymentCod.splice(0);
            this.tabItems.awaitingPaymentElectronic.splice(0);
            this.tabItems.fulfillment.splice(0);
            this.tabItems.cartOrders.splice(0);
            this.tabItems.restoreStock.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const order = result.data.data[i];
              this.fullItems.push(order);

              const tempTotal =
                Number(order.total) + Number(order.delivery_fee);
              const total =
                Math.round((tempTotal + Number.EPSILON) * 100) / 100;

              const collectedTotal =
                Math.round(
                  (Number(order.collected_total) + Number.EPSILON) * 100
                ) / 100;

              let driverName = "N/A";
              if (
                order.delivery &&
                order.delivery.driver &&
                order.delivery.driver.user
              ) {
                driverName = `${order.delivery.driver.user.firstname} ${order.delivery.driver.user.lastname}`;
              }

              const tmpObj = {
                id: order.id,
                deliveryDate: order.delivery_date,
                orderReference: order.order_reference,
                shop: order.shop ? order.shop.name : "Unkown",
                productsCount: order.products.length,
                value: total.toString(),
                collectedValue: collectedTotal.toString(),
                paymentType: order.payment_type,
                status: this.orderStatuses.find(
                  (element) => element.key == order.status
                ).value,
                packerName: order.packer
                  ? `${order.packer.firstname} ${order.packer.lastname}`
                  : "",
                driver: driverName,
                isCreditOrder: order.tags === "credit" ? "Yes" : "No",
              };

              switch (order.status) {
                case "cart": {
                  this.tabItems.cartOrders.push(tmpObj);
                  break;
                }
                case "cancelled": {
                  this.tabItems.cancelledOrders.push(tmpObj);
                  break;
                }
                case "cancelled_process_refund_and_restore_stock":
                case "cancelled_restore_stock": {
                  this.tabItems.restoreStock.push(tmpObj);
                  break;
                }
                case "new": {
                  this.tabItems.newOrders.push(tmpObj);
                  break;
                }
                case "packaging": {
                  this.tabItems.packing.push(tmpObj);
                  break;
                }
                case "alternatives_and_replacements": {
                  this.tabItems.alternativesAndReplacements.push(tmpObj);
                  break;
                }
                case "awaiting_replacement_confirmation": {
                  this.tabItems.awaitingReplacementConfirmation.push(tmpObj);
                  break;
                }
                case "awaiting_payment": {
                  if (tmpObj.paymentType === "cash_on_delivery") {
                    this.tabItems.awaitingPaymentCod.push(tmpObj);
                  } else {
                    this.tabItems.awaitingPaymentElectronic.push(tmpObj);
                  }
                  break;
                }
                case "undelivered":
                case "review_and_assign_driver":
                case "awaiting_delivery":
                case "in_transit":
                case "delivered":
                case "delivered_unpaid":
                case "delivery_rescheduled": {
                  this.tabItems.fulfillment.push(tmpObj);
                  break;
                }
              }
            }

            this.setPaginationDetails(result.data);
            this.updateTabItemTotals();
            this.correctItemOrder();
          })
          .catch((error) => {
            this.handleHttpError(
              error,
              "An error occurred while loading the orders."
            );
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      } else {
        this.paginationDetails.loading = true;

        this.$http
          .post(
            url,
            this.getSearchFilterParams,
            this.sessionStore.getHttpConfig
          )
          .then((result) => {
            this.items.splice(0);
            this.fullItems.splice(0);
            this.tabItems.newOrders.splice(0);
            this.tabItems.packing.splice(0);
            this.tabItems.alternativesAndReplacements.splice(0);
            this.tabItems.awaitingReplacementConfirmation.splice(0);
            this.tabItems.awaitingPaymentCod.splice(0);
            this.tabItems.awaitingPaymentElectronic.splice(0);
            this.tabItems.fulfillment.splice(0);
            this.tabItems.cartOrders.splice(0);
            this.tabItems.cancelledOrders.splice(0);
            this.tabItems.restoreStock.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const order = result.data.data[i];
              this.fullItems.push(order);

              const tempTotal =
                Number(order.total) + Number(order.delivery_fee);
              const total =
                Math.round((tempTotal + Number.EPSILON) * 100) / 100;

              const collectedTotal =
                Math.round(
                  (Number(order.collected_total) + Number.EPSILON) * 100
                ) / 100;

              let driverName = "N/A";
              if (
                order.delivery &&
                order.delivery.driver &&
                order.delivery.driver.user
              ) {
                driverName = `${order.delivery.driver.user.firstname} ${order.delivery.driver.user.lastname}`;
              }

              const tmpObj = {
                id: order.id,
                deliveryDate: order.delivery_date,
                orderReference: order.order_reference,
                shop: order.shop ? order.shop.name : "Unkown",
                productsCount: order.products.length,
                value: total.toString(),
                collectedValue: collectedTotal.toString(),
                paymentType: order.payment_type,
                status: this.orderStatuses.find(
                  (element) => element.key == order.status
                ).value,
                packerName: order.packer
                  ? `${order.packer.firstname} ${order.packer.lastname}`
                  : "",
                driver: driverName,
                isCreditOrder: order.tags === "credit" ? "Yes" : "No",
              };

              switch (order.status) {
                case "cart": {
                  this.tabItems.cartOrders.push(tmpObj);
                  break;
                }
                case "cancelled": {
                  this.tabItems.cancelledOrders.push(tmpObj);
                  break;
                }
                case "cancelled_process_refund_and_restore_stock":
                case "cancelled_restore_stock": {
                  this.tabItems.restoreStock.push(tmpObj);
                  break;
                }
                case "new": {
                  this.tabItems.newOrders.push(tmpObj);
                  break;
                }
                case "packaging": {
                  this.tabItems.packing.push(tmpObj);
                  break;
                }
                case "alternatives_and_replacements": {
                  this.tabItems.alternativesAndReplacements.push(tmpObj);
                  break;
                }
                case "awaiting_replacement_confirmation": {
                  this.tabItems.awaitingReplacementConfirmation.push(tmpObj);
                  break;
                }
                case "awaiting_payment": {
                  if (tmpObj.paymentType === "cash_on_delivery") {
                    this.tabItems.awaitingPaymentCod.push(tmpObj);
                  } else {
                    this.tabItems.awaitingPaymentElectronic.push(tmpObj);
                  }
                  break;
                }
                case "review_and_assign_driver":
                case "awaiting_delivery":
                case "in_transit":
                case "delivered":
                case "delivered_unpaid":
                case "delivery_rescheduled": {
                  this.tabItems.fulfillment.push(tmpObj);
                  break;
                }
              }
            }

            this.setPaginationDetails(result.data);
            this.updateTabItemTotals();
            this.correctItemOrder();
          })
          .catch((error) => {
            this.handleHttpError(
              error,
              "An error occurred while loading the orders."
            );
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      }
    },

    fetchPackers() {
      const body = [
        {
          field: "role",
          operator: "=",
          value: "packer",
        },
      ];

      this.$http
        .post("/users/search", body, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.packers.splice(0);
          for (let i = 0; i < result.data.data.length; i++) {
            this.packers.push({
              id: result.data.data[i].id,
              name: `${result.data.data[i].firstname} ${result.data.data[i].lastname}`,
            });
          }
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            `Unable to fetch packers. Please refresh or contact support if the error persists.`
          );
        });
    },

    searchByValue() {
      if (this.getSearchFilterParams.length === 0) {
        this.fetchDataByUrl("/orders?size=200");
        return;
      }

      this.baseUrl = "/orders/search?size=200";

      this.$http
        .post(
          this.baseUrl,
          this.getSearchFilterParams,
          this.sessionStore.getHttpConfig
        )
        .then((result) => {
          this.items.splice(0);
          this.fullItems.splice(0);
          this.tabItems.newOrders.splice(0);
          this.tabItems.packing.splice(0);
          this.tabItems.alternativesAndReplacements.splice(0);
          this.tabItems.awaitingReplacementConfirmation.splice(0);
          this.tabItems.awaitingPaymentCod.splice(0);
          this.tabItems.awaitingPaymentElectronic.splice(0);
          this.tabItems.fulfillment.splice(0);
          this.tabItems.cartOrders.splice(0);
          this.tabItems.restoreStock.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            const order = result.data.data[i];
            this.fullItems.push(order);

            const tempTotal = Number(order.total) + Number(order.delivery_fee);
            const total = Math.round((tempTotal + Number.EPSILON) * 100) / 100;

            const collectedTotal =
              Math.round(
                (Number(order.collected_total) + Number.EPSILON) * 100) / 100;

            let driverName = "N/A";
            if (
              order.delivery &&
              order.delivery.driver &&
              order.delivery.driver.user
            ) {
              driverName = `${order.delivery.driver.user.firstname} ${order.delivery.driver.user.lastname}`;
            }

            const tmpObj = {
              id: order.id,
              deliveryDate: order.delivery_date,
              orderReference: order.order_reference,
              shop: order.shop ? order.shop.name : "Unkown",
              productsCount: order.products.length,
              value: total.toString(),
              collectedValue: collectedTotal.toString(),
              paymentType: order.payment_type,
              status: this.orderStatuses.find(
                (element) => element.key == order.status
              ).value,
              packerName: order.packer
                ? `${order.packer.firstname} ${order.packer.lastname}`
                : "",
              driver: driverName,
              isCreditOrder: order.tags === "credit" ? "Yes" : "No",
            };

            switch (order.status) {
              case "cart": {
                this.tabItems.cartOrders.push(tmpObj);
                break;
              }
              case "cancelled": {
                this.tabItems.cancelledOrders.push(tmpObj);
                break;
              }
              case "cancelled_process_refund_and_restore_stock":
              case "cancelled_restore_stock": {
                this.tabItems.restoreStock.push(tmpObj);
                break;
              }
              case "new": {
                this.tabItems.newOrders.push(tmpObj);
                break;
              }
              case "packaging": {
                this.tabItems.packing.push(tmpObj);
                break;
              }
              case "alternatives_and_replacements": {
                this.tabItems.alternativesAndReplacements.push(tmpObj);
                break;
              }
              case "awaiting_replacement_confirmation": {
                this.tabItems.awaitingReplacementConfirmation.push(tmpObj);
                break;
              }
              case "awaiting_payment": {
                if (tmpObj.paymentType === "cash_on_delivery") {
                  this.tabItems.awaitingPaymentCod.push(tmpObj);
                } else {
                  this.tabItems.awaitingPaymentElectronic.push(tmpObj);
                }
                break;
              }
              case "review_and_assign_driver":
              case "awaiting_delivery":
              case "in_transit":
              case "delivered":
              case "delivered_unpaid":
              case "delivery_rescheduled": {
                this.tabItems.fulfillment.push(tmpObj);
                break;
              }
            }
          }

          this.setPaginationDetails(result.data);
          this.updateTabItemTotals();
          this.correctItemOrder();
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.success("An error occurred while searching orders.");
        });
    },

    showDialogAssignPacker(order) {
      this.assignPackerShortcutOrder = order;
      this.dialogAssignPacker = true;
    },

    updateItemsPerPage(newValue) {
      const searchParams = new URLSearchParams({
        size: newValue,
      });

      const url = `${this.paginationDetails.path}?${searchParams.toString()}`;

      this.fetchDataByUrl(url);
    },

    setTabHeading() {
      this.tabHeadings.splice(0);

      if (
        this.sessionStore.user.roles.includes("super_admin") ||
        this.sessionStore.user.roles.includes("admin") ||
        this.sessionStore.user.roles.includes("agent") ||
        this.sessionStore.user.roles.includes("warehouse_manager")
      ) {
        this.tabHeadings.push({
          id: "newOrders",
          title: "New",
          anchor: "new",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: true,
          enableActionGoToItemPage: false,
          enableActionApprove: false,
          enableActionAssignPacker: true,
          headers: [
            {
              text: "Order #",
              align: "start",
              sortable: false,
              value: "orderReference",
            },
            { text: "Delivery Date", value: "deliveryDate" },
            { text: "Shop", value: "shop" },
            { text: "No Products", value: "productsCount" },
            { text: "Value", value: "value" },
            { text: "Actions", value: "actions", sortable: false },
          ],
          deleteTooltip: "Delete",
          showFilterBy: false,
        });
      }

      if (
        this.sessionStore.user.roles.includes("super_admin") ||
        this.sessionStore.user.roles.includes("admin") ||
        this.sessionStore.user.roles.includes("agent") ||
        this.sessionStore.user.roles.includes("warehouse_manager") ||
        this.sessionStore.user.roles.includes("packer")
      ) {
        this.tabHeadings.push({
          id: "packing",
          title: "Packing",
          anchor: "packing",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: true,
          enableActionGoToItemPage: false,
          enableActionApprove: true,
          enableActionAssignPacker: false,
          headers: [
            {
              text: "Order #",
              align: "start",
              sortable: false,
              value: "orderReference",
            },
            { text: "Delivery Date", value: "deliveryDate" },
            { text: "Shop", value: "shop" },
            { text: "No Products", value: "productsCount" },
            { text: "Value", value: "value" },
            { text: "Packer", value: "packerName" },
            { text: "Actions", value: "actions", sortable: false },
          ],
          deleteTooltip: "Delete",
          showFilterBy: false,
        });

        this.tabHeadings.push({
          id: "alternativesAndReplacements",
          title: "Alternatives & Replacements",
          anchor: "alt_and_replacements",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: true,
          enableActionGoToItemPage: true,
          enableActionApprove: false,
          enableActionAssignPacker: false,
          headers: [
            {
              text: "Order #",
              align: "start",
              sortable: false,
              value: "orderReference",
            },
            { text: "Delivery Date", value: "deliveryDate" },
            { text: "Shop", value: "shop" },
            { text: "No Products", value: "productsCount" },
            { text: "Value", value: "value" },
            { text: "Packer", value: "packerName" },
            { text: "Actions", value: "actions", sortable: false },
          ],
          deleteTooltip: "Delete",
          showFilterBy: false,
        });

        this.tabHeadings.push({
          id: "awaitingReplacementConfirmation",
          title: "Awaiting Replacement Confirmation",
          anchor: "await_replacement_confirmation",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: true,
          enableActionGoToItemPage: true,
          enableActionApprove: false,
          enableActionAssignPacker: false,
          headers: [
            {
              text: "Order #",
              align: "start",
              sortable: false,
              value: "orderReference",
            },
            { text: "Delivery Date", value: "deliveryDate" },
            { text: "Shop", value: "shop" },
            { text: "No Products", value: "productsCount" },
            { text: "Value", value: "value" },
            { text: "Packer", value: "packerName" },
            { text: "Actions", value: "actions", sortable: false },
          ],
          deleteTooltip: "Delete",
          showFilterBy: false,
        });
      }

      if (
        this.sessionStore.user.roles.includes("super_admin") ||
        this.sessionStore.user.roles.includes("admin") ||
        this.sessionStore.user.roles.includes("agent") ||
        this.sessionStore.user.roles.includes("packer") ||
        this.sessionStore.user.roles.includes("warehouse_manager")
      ) {
        this.tabHeadings.push({
          id: "restoreStock",
          title: "Restore Stock",
          anchor: "restore_stock",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: true,
          enableActionApprove: true,
          enableActionAssignPacker: false,
          headers: [
            {
              text: "Order #",
              align: "start",
              sortable: false,
              value: "orderReference",
            },
            { text: "Delivery Date", value: "deliveryDate" },
            { text: "Shop", value: "shop" },
            { text: "No Products", value: "productsCount" },
            { text: "Value", value: "value" },
            { text: "Actions", value: "actions", sortable: false },
          ],
          approveTooltip: "Restock completed",
          showFilterBy: true,
        });
      }

      if (
        this.sessionStore.user.roles.includes("super_admin") ||
        this.sessionStore.user.roles.includes("admin") ||
        this.sessionStore.user.roles.includes("agent") ||
        this.sessionStore.user.roles.includes("warehouse_manager")
      ) {
        this.tabHeadings.push({
          id: "awaitingPaymentCod",
          title: "Awaiting COD Payment",
          anchor: "await_cod_payment",
          icon: "cart-arrow-right",
          enableActionEdit: false,
          enableActionDeactivate: false,
          enableActionDelete: true,
          enableActionGoToItemPage: true,
          enableActionApprove: true,
          enableActionAssignPacker: false,
          headers: [
            {
              text: "Order #",
              align: "start",
              sortable: false,
              value: "orderReference",
            },
            { text: "Delivery Date", value: "deliveryDate" },
            { text: "Shop", value: "shop" },
            { text: "No Products", value: "productsCount" },
            { text: "Value", value: "value" },
            { text: "Collected Value", value: "collectedValue" },
            { text: "Credit Order", value: "isCreditOrder" },
            { text: "Driver", value: "driver" },
            { text: "Actions", value: "actions", sortable: false },
          ],
          deleteTooltip: "Mark Unpaid",
          approveTooltip: "Mark Paid",
          showTotalCodValue: true,
          showFilterBy: true,
        });

        this.tabHeadings.push({
          id: "awaitingPaymentElectronic",
          title: "Awaiting Electronic Payment",
          anchor: "await_electronic_payment",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: true,
          enableActionGoToItemPage: true,
          enableActionApprove: false,
          enableActionAssignPacker: false,
          headers: [
            {
              text: "Order #",
              align: "start",
              sortable: false,
              value: "orderReference",
            },
            { text: "Delivery Date", value: "deliveryDate" },
            { text: "Shop", value: "shop" },
            { text: "No Products", value: "productsCount" },
            { text: "Value", value: "value" },
            { text: "Actions", value: "actions", sortable: false },
          ],
          deleteTooltip: "Delete",
          showFilterBy: false,
        });

        this.tabHeadings.push({
          id: "fulfillment",
          title: "Fulfillment",
          anchor: "fulfillment",
          icon: "cart-arrow-right",
          enableActionEdit: false,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: false,
          enableActionAssignPacker: false,
          headers: [
            {
              text: "Order #",
              align: "start",
              sortable: false,
              value: "orderReference",
            },
            { text: "Delivery Date", value: "deliveryDate" },
            { text: "Shop", value: "shop" },
            { text: "No Products", value: "productsCount" },
            { text: "Value", value: "value" },
            { text: "Status", value: "status" },
            { text: "Driver", value: "driver" },
            // { text: "Actions", value: "actions", sortable: false },
          ],
          deleteTooltip: "Unpaid",
          showTotalCodValue: true,
          showFilterBy: true,
        });

        this.tabHeadings.push({
          id: "cancelledOrders",
          title: "Cancelled Orders",
          anchor: "cancelled",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: true,
          enableActionApprove: false,
          enableActionAssignPacker: false,
          headers: standardTableHeaders,
          showFilterBy: true,
        });

        this.tabHeadings.push({
          id: "cartOrders",
          title: "Cart Orders",
          anchor: "card",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: true,
          enableActionGoToItemPage: true,
          enableActionApprove: false,
          enableActionAssignPacker: false,
          headers: standardTableHeaders,
          deleteTooltip: "Delete",
          showFilterBy: false,
        });
      }
    },

    clearFilters() {
      this.filterByShopParams.value = null;
      this.filterByDriverParams.value = null;
      this.filterByDateParams.value = null;
      this.searchByValue();
    },

    updateSearchParameters(type, val) {
      if (type === "driver") {
        this.filterByDriverParams.value = val;
      }

      if (type === "date") {
        this.filterByDateParams.value = val;
      }

      this.searchByValue();
    },

    updateShopSearchParam(val) {
      this.filterByShopParams.value = val;
      this.searchByValue();
    },

    updateTabItemTotals() {
      for (const [key, value] of Object.entries(this.tabItems)) {
        let totalSum = 0;
        let collectedSum = 0;
        value.forEach((item) => {
          if (
            item.paymentType === "cash_on_delivery" &&
            item.isCreditOrder === "No"
          ) {
            totalSum += Number(item.value);
            collectedSum += Number(item.collectedValue);
          }
        });

        const totalVal = Math.round((totalSum + Number.EPSILON) * 100) / 100;
        this.tabItemsTotals[key] = totalVal.toString();

        const collectedVal =
          Math.round((collectedSum + Number.EPSILON) * 100) / 100;
        this.tabItemsTotalsCollected[key] = collectedVal.toString();
      }
    },

    correctItemOrder() {
      this.tabItems;

      for (const [key] of Object.entries(this.tabItems)) {
        this.tabItems[key].sort((a, b) => {
          const dateA = new Date(a.deliveryDate).getTime();
          const dateB = new Date(b.deliveryDate).getTime();
          return dateA > dateB ? 1 : -1;
        });
      }
    },
  },
  mounted() {
    this.setTabHeading();

    this.fetchDataByUrl(this.baseUrl);

    this.fetchPackers();

    this.dataLoadInterval = setInterval(() => {
      this.fetchDataByUrl(this.baseUrl);
    }, 15000);

    this.$http
      .get("/drivers?size=200", this.sessionStore.getHttpConfig)
      .then((result) => {
        const drivers = result.data.data;

        this.driverList.splice(0);
        this.driverUserList.splice(0);
        for (let i = 0; i < drivers.length; i++) {
          this.driverList.push(drivers[i]);
          this.driverUserList.push({
            driverId: drivers[i].id,
            userId: drivers[i].user.id,
            friendlyName: `${drivers[i].user.firstname} ${drivers[i].user.lastname}`,
          });
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading driver details. Please try again later or contact support."
        );
      });
  },
  beforeDestroy() {
    clearInterval(this.dataLoadInterval);
  },
};
</script>
